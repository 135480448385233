#qntCounter {
    text-align: right;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1rem;
    color: #644b17;
    margin-bottom: 1rem;
    margin-right: 1%;
    p {
        font-weight: 100;
    }
    span {
        font-weight: bold;
    }
}

.paramsTable {
    .multiLine {
        td{
            border-bottom: 1px solid #e0e0e0;
            min-height: 42px !important;
            line-height: 42px !important;
            height: auto;
        }
    }
}
.listaIndividuos{
    margin-top: 3rem !important;
}