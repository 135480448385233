.filter {
    background: #FFFFFF;
    border: 1px solid rgba(100, 75, 23, 0.6);
    box-sizing: border-box;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 2%;
    margin: 50px 0 20px 0;
}
.filterTitle{
    margin-bottom: 1rem !important;
    color: #644b17;
    text-align: center;
    font-weight: 700 !important;
}
.outCard{
    padding-right: 20px;
    padding-bottom: 20px;
    &:nth-child(3n){
        padding-right: 0;
    }
    .userCard{
        background: #FFFFFF;
        border: 1px solid rgba(100, 75, 23, 0.6);
        box-sizing: border-box;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        padding: 5%;
    }
    .inputDefault{
        margin-bottom: 15px !important;
        margin-top: 0 !important;
    }
    .buttonBox{
        text-align: center;
        .btn-secondary{
            width: 50%;
        }
    }
}