.header{
    border-bottom: 10px solid #644B17;
    img{
        padding: 20px 0;
        margin: 0 auto;
        display: block;
    }
    margin-bottom: 3em;
}
.headerTitle{
    margin: 30px 0 !important;
    color: #644b17;
    text-align: center;
    font-weight: 700 !important;
}
.loginHeader{
    background-color: rgba(100, 75, 23, 0.6);
    border-bottom: 10px solid #644B17;
    img{
        padding: 20px 0;
        margin: 0 auto;
        display: block;
    }
    margin-bottom: 3em;
}