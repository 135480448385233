.filter {
	background: #ffffff;
	border: 1px solid rgba(100, 75, 23, 0.6);
	box-sizing: border-box;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	padding: 2%;
	margin: 0 0 20px 0;
	.inputDefault {
		margin-bottom: 15px !important;
		margin-top: 0 !important;
	}
}
.filterTitle {
	margin-bottom: 1rem !important;
	color: #644b17;
	text-align: center;
	font-weight: 700 !important;
}
.customTable {
	background: #ffffff;
	border: 1px solid rgba(100, 75, 23, 0.6);
	box-sizing: border-box;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	text-align: left !important;
	th,
	td {
		text-align: left;
	}
	.tableHead {
		background: rgba(100, 75, 23, 0.6);
		th {
			color: #fff;
			font-weight: bold;
			cursor: pointer;
		}
		.MuiCheckbox-root {
			color: #fff;
			&:hover {
				background-color: rgba(255, 255, 255, 0.1);
			}
		}
	}
}

.imagesContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: left;
	overflow: hidden;
	margin-top: 10px;
	// .gridList {
	//   flex-wrap: nowrap;
	//   transform: translateZ(0);
	// }
	img {
		&:hover {
			cursor: pointer;
		}
	}
	#expandButton {
		position: absolute;
		color: #fff;
		right: 5px;
		bottom: 5px;
		font-size: 20px;
		opacity: 90%;
		&:hover {
			cursor: pointer;
		}
	}
}

.checkBoxLabel {
	display: inline-block;
	line-height: 42px;
}
.faunaCheckBox {
	&.MuiCheckbox-root {
		color: rgba(100, 75, 23, 0.6) !important;
		&:hover {
			background-color: rgba(255, 255, 255, 0.1);
		}
	}
}

.mapBox {
	margin-bottom: 1em !important;
}
