.loginBox{
    display: block;
    width: 320px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    
    .inputDefault{
        min-width: 320px;
        width: 100%;
        text-align: center;
        margin-bottom: 15px !important;
    }
}
.loginTitle{
    color: #644b17;
    text-align: center;
    font-weight: 700 !important;
}