.paramsTable{
    th, td{
        border-bottom: none !important;
        height: 42px;
        line-height: 42px;
    }
    tr{
        border-bottom: 1px solid rgba(224, 224, 224, 1)
    }
    .MuiTableCell-root{
        border-bottom: none !important;
        height: 42px;
        line-height: 42px;
        &>.inputDefault{
            margin-bottom: unset !important;
            border-bottom: none !important;
            .MuiInput-underline:before, .MuiInput-underline:after{
                border-bottom: none !important;
            }
            label + .MuiInput-formControl {
                margin-top: 10px !important;
            }
            .MuiInputBase-input{
                height: 42px;
                line-height: 42px;
                padding: 0;
                font-size: 0.8rem !important;
            }
        }
    }
    .MuiTableRow-root{
        border-bottom: 1px solid rgba(224, 224, 224, 1)
    }
    .parametersTable{
        padding: 0 10px;
    }
}