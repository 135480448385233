.modalBg {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: RGB(255, 255 ,255 , 0.5);
    z-index: 1200;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    .modalBox {
        width: 75%;
        max-width: 1000px;
        background-color: #fff;
        border-radius: 5px;
        min-height: 30pc;
        max-height: 90%;
        overflow-y: auto;
        border: 1px solid rgba(100, 75, 23, 0.6);
        box-sizing: border-box;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        overflow-x: scroll !important;
        overflow-y: unset !important;
        &.smallSize{
            width: 25% !important;
            height: 10% !important;
            min-height: 15pc !important;
            overflow-x: unset !important;
            overflow-y: unset !important;
            .modalBody{
                margin: 5px;
                width: 100%;
                min-height: unset !important;
                position: relative;
                .expandedImage{
                    position: relative;
                    transform: translate(-50%, -50%);
                    left: 50%;
                    top: 50%;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .modalSmallSize {
                margin-bottom: 3pc;
                text-align: center !important;
            }
        }
        .modalHeader{
            background-color: #b5a990;
            height: 40px !important;
            min-height: 40px !important;
            width: 100% !important;
            padding: 0 20px;
            p{
                font-weight: bold;
                color: #644b17;
                font-size: 18px;
                line-height: 40px;
                display: inline-block;
            }
            #closeIcon{
                display: block;
                float: right;
                font-size: 20px;
                margin: 10px 0;
                color: #644b17;
                cursor: pointer;
            }
        }
        .modalBody{
            margin: 5px;
            width: 100%;
            min-height: 360px;
            position: relative;
            .expandedImage{
                position: relative;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}
