.btn-primary{
    background-color: #F28C00 !important;
    color: #fff !important;
    min-width: 320px;
    width: 100%;
    &.btn-disabled{
        background: rgba(242, 140, 0, 0.6) !important;
    }
}
.btn-orange-secondary{
    border: 1px solid #F28C00 !important;
    color: #F28C00 !important;
    background-color: #fff !important;
}
.btn-secondary{
    @extend .btn-orange-secondary;
    min-width: 320px;
    width: 100%;
    &.Mui-disabled{
        border: 1px solid rgba(0, 0, 0, 0.36) !important;
        color: rgba(0, 0, 0, 0.36) !important;
    }
}
.btn-small{
    font-size: 0.8em !important;
    padding: 2% !important;
    margin: 1% !important;
    svg{
        margin-left: 10% !important;
    }
}
.inputDefault{
    min-width: 320px;
    width: 100%;
    text-align: left;
    margin-bottom: 15px;
    margin-top: 0;
}
.dateInput{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.inputCheckbox{
    color: #F28C00 !important;
}
.inputBold input{
    font-weight: bold !important;
}
.root {
    display: 'flex',
}
.appBar {
    width: calc(100% - 280px);
    margin-left: 280px;
}
.drawer {
    width: 280;
    flex-shrink: 0;
}
.drawerPaper {
    width: 280px,
}
.content{
    flex-grow: 1;
    background-color: #fff;
    margin-left: 280px,
}
body{
    background-color: #ffffff !important;
}
.basicContainer{
    max-width: 1000px;
    margin: 0 auto;
}
.alert{
    margin-bottom: 2em;
    margin-top: 2em;
}

.MuiPickersDay-daySelected{
    background-color:rgba(242, 140, 0, 1) !important
}

.MuiFormLabel-root.Mui-focused{
    color: #000 !important;
}
.MuiInput-underline:after{
    border-bottom: 2px solid #000 !important;
}

.basicAccordion{
    background: #FFFFFF;
    border: 1px solid rgba(100, 75, 23, 0.6);
    box-sizing: border-box;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px !important;
    padding: 0;
    margin: 20px 0;
    .accordionHeader{
        background-color: #b5a990;
        height: 40px !important;
        min-height: 40px !important;
        p{
            font-weight: bold;
            color: #644b17;
            font-size: 18px;
            line-height: 40px;
        }
        .MuiAccordionSummary-content{
            margin: 0 !important;
            height: 40px !important;
            &.Mui-expanded{
                margin: 0 !important;
                height: 40px !important;
            }
        }
    }
}

.box-content-primary {
    background: #FFFFFF;
    border: 1px solid rgba(100, 75, 23, 0.6);
    box-sizing: border-box;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 2%;
    margin: 50px 0 20px 0;
    &.traps{
        margin: 0;
    }
    &.center{
        margin: 0 auto;
    }
    &.blocked-itens{
        margin-bottom: 1em;
    }
}

.dividerFull {
    width: 100%;
    background-color: unset !important;
}

.dividerSolid {
    width: 100%;
    // background-color: unset !important;
}

.selectProject{
    .inputDefault{
        label, input, #demo-simple-select, svg{
            color: #fff !important;
        }
        .MuiInput-underline{
            &:after, &:before{
                border-bottom: 1px solid #fff !important;
            }
        }
    }
}

.MuiPagination-root{
    margin: 0 auto !important;
    margin-top: 1em !important;
}

.loadingBg{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: RGB(255, 255 ,255 , 0.5);
    z-index: 9991;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.MuiCheckbox-root.Mui-checked .MuiIconButton-label {
    color: #F28C00 !important;
}