.sidebar {
    &> div{
        background: rgba(100, 75, 23, 0.6);
        color: #fff;
    }
    .logo {
        padding: 15px;
        text-align: center;
    }
    .activate{
        background: rgba(255, 255, 255, 0.2) !important;
    }
    a, span{
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        text-decoration: unset !important;
        line-height: 20px;
        &:hover,
        &:visited,
        &:focus,
        &:active{
            color: #ffffff;
            text-decoration: unset !important;
            border: unset !important
        }
    }
    svg{
        font-size: 20px;
        margin-right: 5px;
    }
    .subMenu{
        a{
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
            text-decoration: unset !important;
            line-height: 20px;
            &:hover,
            &:visited,
            &:focus,
            &:active{
                color: #ffffff;
                text-decoration: unset !important;
                border: unset !important
            }
            padding-left: 15%;
        }
    }

    #logOut{
        position: absolute;
        right: 0;
        cursor: pointer;
        padding-right: 2%;
        svg, span {
            line-height: 40px;
            font-weight: bold;
            font-size: 16px;
            color: #644b17;
        }
    }
}